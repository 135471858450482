import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/hovrlogo.png"
      alt="Logo"
      {...props}
      width="75px"
      className="logoresponsive"
    />
  );
};

export default Logo;
