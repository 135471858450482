import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegramPlane,
  FaDiscord,
} from "react-icons/fa";
import { SiSinaweibo } from "react-icons/si";
import { Link as RouterLink } from "react-router-dom";
import { FiTwitter } from "react-icons/fi";
import Logo from "src/component/Logo";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/User";
import { FiFacebook } from "react-icons/fi";
const useStyles = makeStyles((theme) => ({
  footerBox: {
    display: "flex",
    padding: "35px 0px 0px",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "auto",
    borderTop: "2px solid rgba(2, 156, 220, 1)",

    "& .mainBox": {
      position: "relative",
      zIndex: "999",
      "& p": {
        fontSize: "16px",
        fontWeight: "400",
      },
    },
  },
  footerLinkPages: {
    width: "100%",
    position: "relative",
    padding: "30px 0px",
    borderTop: "1px solid rgba(225, 225, 225, 1)",
    "& p": {
      color: "#959595",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  socialgrid: {
    display: "flex",
    padding: "16px 0px 24px",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      background: "#fff",
      borderRadious: "5px",
      borderRadius: "5px",
      marginRight: "10px",
      "&:hover": {
        background: "#1074bc",
        "& svg": {
          fontSize: "23px",
          color: "#fff",
        },
      },
    },
    "& svg": {
      fontSize: "23px",
      color: "#1074bc",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useNavigate();
  const user = useContext(UserContext);
  return (
    <>
      <Box className={classes.footerBox}>
        <Container maxWidth="lg">
          <Box className="mainBox">
            <Box align="center">
              <RouterLink to="/">
                <Logo className="logoImg" />
              </RouterLink>
            </Box>
            <Box className={classes.socialgrid}>
              <Link href={user?.socialData[0]?.link} target="_blank">
                <IconButton>
                  <FiFacebook />
                </IconButton>
              </Link>

              <Link href={user?.socialData[3]?.link} target="_blank">
                <IconButton>
                  <FaTelegramPlane />
                </IconButton>
              </Link>
              <Link href={user?.socialData[2]?.link} target="_blank">
                <IconButton>
                  <FaInstagram />
                </IconButton>
              </Link>

              <Link href={user?.socialData[1]?.link} target="_blank">
                <IconButton>
                  <FiTwitter />
                </IconButton>
              </Link>
              <Link
                href="https://discord.com/invite/9HCNTyqkwa"
                target="_blank"
              >
                <IconButton>
                  {" "}
                  <FaDiscord />
                </IconButton>
              </Link>
              <Link href="https://weibo.com/pawsome" target="_blank">
                <IconButton>
                  <SiSinaweibo />
                </IconButton>
              </Link>
            </Box>
            <Box className="displayCenter " style={{ gap: "16px" }} pb={4}>
              <Typography
                variant="body1"
                className="bottomdescription"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  history("/about-us");
                }}
              >
                About Us
              </Typography>

              <Typography
                variant="body1"
                onClick={() => history("/privacy")}
                className="bottomdescription"
                style={{ cursor: "pointer" }}
              >
                Privacy Policy
              </Typography>

              <Typography
                variant="body1"
                onClick={() => history("/terms")}
                className="bottomdescription"
                style={{ cursor: "pointer" }}
              >
                {" "}
                Terms And Conditions
              </Typography>
              <Typography
                variant="body1"
                onClick={() => history("/stakeholder-rewards")}
                style={{ cursor: "pointer" }}
              >
                Rewards
              </Typography>
            </Box>
          </Box>
        </Container>

        <Container>
          <Box className={classes.footerLinkPages}>
            <Box className=" item">
              <Typography variant="body1">
                Copyright © 2022-23 PAWSOME, ALL RIGHTS RESERVED
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
