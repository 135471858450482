import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import UserContext from "src/context/User";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import AuthContext from "src/context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import {
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { RPC_URL, ACTIVE_NETWORK } from "./constants";

export const QIECHAIN = {
  id: ACTIVE_NETWORK,
  name: "Qie Blockchain",
  network: "Qi Mainnet",
  iconUrl:
    "https://mainnet.qiblockchain.online/images/blockscout_logo-b23279cd0a69d8b32635c03fc94bbd64.svg?vsn=d",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Qi",
    symbol: "QIE",
  },
  rpcUrls: {
    public: { http: [RPC_URL] },
    default: { http: [RPC_URL] },
  },
  blockExplorers: {
    etherscan: {
      name: "qi.mainnet",
      url: "https://mainnet.qiblockchain.online",
    },
    default: {
      name: "qi.mainnet",
      url: "https://mainnet.qiblockchain.online",
    },
  },
  contracts: {
    multicall3: {
      address: "",
      blockCreated: 11_907_934,
    },
  },
};

const projectId = "674f6d96bb30add84e0bb50eece400ca";

function App() {
  const theme = createTheme();

  //rainbowkit and wagmi connectors implementation for establishing web3 connectivity
  const { chains, publicClient } = configureChains(
    [QIECHAIN],
    [publicProvider()]
  );
  console.log("chains-=-=-=-=", chains);
  const connectors = connectorsForWallets([
    // ...wallets,
    {
      groupName: "pawsome.host",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <div className="bg-primary w-full overflow-hidden">
      {" "}
      <ToastContainer />{" "}
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          modalSize="compact"
          theme={lightTheme({
            accentColor: "#029CDC",
            accentColorForeground: "#fff",
            borderRadius: "small",
            fontStack: "system",
            overlayBlur: "small",
            fontWeight: "500px",
          })}
        >
          <SkeletonTheme baseColor="#d6eac5" highlightColor="#d9d9d9">
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <UserContext>
                  <AuthContext>
                    <RouterProvider router={router} />
                  </AuthContext>
                </UserContext>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </SkeletonTheme>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
