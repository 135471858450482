import {
  Box,
  Button,
  FormControl,
  IconButton,
  makeStyles,
  TableContainer,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import Users from "./Users";
import Items from "./Items";
import Dogs from "./Dogs";
import Events from "./Events";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import NoDataFound from "./NoDataFound";
const useStyles = makeStyles((theme) => ({
  MyprofileTabsBox: {
    "& .active": {
      color: "#58c9f0",
      borderRadius: "0px",
      borderBottom: "2px solid #58c9f0",
      marginRight: "20px",
      textTransform: "capitalize",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        marginRight: "4px",
      },
    },
    "& .inactive": {
      fontSize: "16px",
      marginRight: "20px",
      borderBottom: "2px solid transparent",
      borderRadius: "0px",
      textTransform: "capitalize",
      color: "#383838",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        marginRight: "4px",
      },
    },
  },
}));

export default function SerachTab({ handleClose }) {
  const [searchData, setSearchData] = useState();
  const classes = useStyles();
  const [tabview, setTabView] = useState();
  console.log(">>>>>>>>tabview", tabview);
  const [search, setSearch] = useState("");
  console.log(">>>>>>>searchData", searchData);
  const globallySearchHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.globallySearch,
        params: {
          search: search,
        },
      });
      if (res.data.code === 200) {
        setSearchData(res.data.data);
        setTabView(
          res.data.data && res.data.data?.userData?.length > 0
            ? "Users"
            : res.data.data && res.data.data?.dogData?.length
            ? "Dogs"
            : res.data.data && res.data.data?.eventData?.length > 0
            ? "Events"
            : res.data.data && res.data.data?.itemData?.length > 0
            ? "Items"
            : ""
        );
      }
      console.log(">>>>>>res", res);
    } catch (error) {}
  };

  return (
    <Box className={classes.MyprofileTabsBox}>
      <Box>
        <Box style={{ padding: "20px" }}>
          <FormControl fullWidth>
            <TextField
              type="text"
              variant="outlined"
              name="email"
              placeholder="Search by user name, Items, and NFTs"
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    position="end"
                    style={{ padding: "0px" }}
                    onClick={() => globallySearchHandler()}
                  >
                    <BiSearch style={{ fontSize: "20px" }} />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
        </Box>

        <TableContainer>
          <Box className="displayStart" style={{ padding: "0 20px" }}>
            {searchData && searchData?.userData?.length > 0 && (
              <Button
                className={tabview === "Users" ? "active" : "inactive"}
                onClick={() => setTabView("Users")}
              >
                Users
              </Button>
            )}
            {searchData && searchData?.itemData?.length > 0 && (
              <Button
                className={tabview === "Items" ? "active" : "inactive"}
                onClick={() => setTabView("Items")}
              >
                Items
              </Button>
            )}

            {searchData && searchData?.dogData?.length > 0 && (
              <Button
                className={tabview === "Dogs" ? "active" : "inactive"}
                onClick={() => setTabView("Dogs")}
              >
                Dogs
              </Button>
            )}
            {searchData && searchData?.eventData?.length > 0 && (
              <Button
                className={tabview === "Events" ? "active" : "inactive"}
                onClick={() => setTabView("Events")}
              >
                Events
              </Button>
            )}
          </Box>
        </TableContainer>
      </Box>
      {searchData && searchData?.userData?.length > 0 && (
        <>
          {tabview === "Users" && (
            <Users handleClose={handleClose} searchData={searchData} />
          )}
        </>
      )}
      {searchData && searchData?.itemData?.length > 0 && (
        <>
          {tabview === "Items" && (
            <Items
              searchData={searchData?.itemData}
              handleClose={handleClose}
            />
          )}
        </>
      )}

      {searchData && searchData?.dogData?.length > 0 && (
        <>
          {tabview === "Dogs" && (
            <Dogs handleClose={handleClose} searchData={searchData?.dogData} />
          )}
        </>
      )}

      {searchData && searchData?.eventData?.length > 0 && (
        <>
          {tabview === "Events" && (
            <Events
              searchData={searchData?.eventData}
              handleClose={handleClose}
            />
          )}
        </>
      )}
      <Box>
        {searchData &&
          searchData?.userData?.length === 0 &&
          searchData &&
          searchData?.dogData?.length === 0 &&
          searchData &&
          searchData?.eventData?.length === 0 &&
          searchData &&
          searchData?.itemData?.length === 0 && <NoDataFound />}
      </Box>
    </Box>
  );
}
