import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: "-30px",
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },

  //     progressBar:{
  // height:"3px",
  //     },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box style={{ textAlign: "center" }}>
        {/* <LinearProgress height={10} /> */}
        <img
          src="images/hovrlogo.png"
          width="200px"
          alt="loader"
          style={{ color: "#000" }}
        />
      </Box>
    </div>
  );
}
