// live-----
export const NetworkContextName = "QI CHAIN-MAINNET";
export const ACTIVE_NETWORK = 5656;
// export const generativeNFTAddress =
//   "0x85e09BE9b26d9ad7Ef8AbE84291B800BBfA9F806"; //live new 1 march ---------
export const generativeNFTAddress =
  "0x85A154fF76c926CA59fcc8A9db1E7E08C93bCEA5"; //live new 12 march ---------
export const mulltiCallAddress = "0x8304E6187ceABc5028C7Fc990B3342fEC239Df8E";
export const nftContractAddress = "0x9f0D6f0c4F641481105c40E45677687e5f3839c3";
export const marketPlaceAddress = "0x59D0b91CF82Fddeb56e769B8559cdD02ce956672";

export const RPC_URL = "https://rpc-main2.qiblockchain.online/"; //mainNet
export const deadAddress = "0x0000000000000000000000000000000000000000";
export const mintAwsURL = "https://pawsomedata.s3.amazonaws.com/metadata";
export const walletAddressForGameFund =
  "0x04880dF0a706BDFC2d6d069B77A7a3e9c863efDC";

// staging-----
// export const NetworkContextName = "QI CHAIN-MAINNET";
// export const ACTIVE_NETWORK = 5656;
// // export const RPC_URL = "https://rpc.qiblockchain.online/"; //testNet
// // export const generativeNFTAddress =
// //   "0x0Fd491B385Dca497e13B7B1D39436dE02Ed6e1eb"; //live
// export const generativeNFTAddress =
//   "0x85A154fF76c926CA59fcc8A9db1E7E08C93bCEA5";
// export const mulltiCallAddress = "0x8304E6187ceABc5028C7Fc990B3342fEC239Df8E";
// export const nftContractAddress = "0x9f0D6f0c4F641481105c40E45677687e5f3839c3";
// export const marketPlaceAddress = "0x59D0b91CF82Fddeb56e769B8559cdD02ce956672";
// export const RPC_URL = "https://rpc-main2.qiblockchain.online/"; //mainNet
// export const deadAddress = "0x0000000000000000000000000000000000000000";
// export const mintAwsURL = "https://pawsomedata.s3.amazonaws.com/metadata";
// export const walletAddressForGameFund =
//   "0x04880dF0a706BDFC2d6d069B77A7a3e9c863efDC";

//UNITY_URL live

export const dogRaceURl = "https://unity.pawsome.host/build/index.html?name=";
export const randomRoom =
  "https://unity.pawsome.host/RandomRoomWebGL/index.html?name=";
export const specialRoom =
  "https://unity.pawsome.host/GroupRoomWebGL/index.html?name=";
export const tournamentRoom =
  "https://unity.pawsome.host/TournamentWebGL/index.html?name=";

// UNITY_URL staging
// export const randomRoom =
//   "https://unity-pawsome.mobiloitte.org/RandomRoomWebGL/index.html?name=";
// export const specialRoom =
//   "https://unity-pawsome.mobiloitte.org/GroupRoomWebGL/index.html?name=";
// export const tournamentRoom =
//   "https://unity-pawsome.mobiloitte.org/TournamentWebGL/index.html?name=";
// export const dogRaceURl =
//   "https://unity-pawsome.mobiloitte.org/index.html?name=";

// UNITY_URL local
// export const dogRaceURl = "http://172.16.11.253:2005/build/index.html?name=";
// export const randomRoom =
//   "http://172.16.11.253:2005/RandomRoomWebGL/index.html?name=";
// export const specialRoom =
//   "http://172.16.11.253:2005/GroupRoomWebGL/index.html?name=";
// export const tournamentRoom =
//   "http://172.16.11.253:2005/TournamentWebGL/index.html?name=";

export const NetworkDetails = [
  {
    chainId: "0x2603",
    chainName: "QI Mainnet",
    nativeCurrency: {
      name: "QI",
      symbol: "QIE",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://mainnet.qiblockchain.online/"],
  },
  // {
  //   chainId: "0x2603",
  //   chainName: "QI Testnet",
  //   nativeCurrency: {
  //     name: "QI",
  //     symbol: "QI",
  //     decimals: 18,
  //   },
  //   rpcUrls: [RPC_URL],
  //   blockExplorerUrls: [
  //     "https://testnet.qiblockchain.online/explorer/explorer",
  //   ],
  // },
];

export const dogSpecifications = [
  {
    dogSpeed: 15, // 15-20
    dogModel: 0, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 0, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 0, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 0, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 16, // 15-20
    dogModel: 1, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 1, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 1, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 1, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 17, // 15-20
    dogModel: 2, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 2, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 2, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 2, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 18, // 15-20
    dogModel: 3, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 3, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 3, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 3, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 19, // 15-20
    dogModel: 4, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 4, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 4, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 4, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 20, // 15-20
    dogModel: 5, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 5, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 5, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 5, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 18.5, // 15-20
    dogModel: 6, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 6, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 6, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 6, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 19.5, // 15-20
    dogModel: 7, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 7, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 7, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 7, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
  {
    dogSpeed: 16.5, // 15-20
    dogModel: 8, // 0-9
    bodyBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Dog Texture
    eyeModel: 8, // 0-9
    eyeBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Eye Wear Texture
    headModel: 8, // 0-9
    headBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Head wear Texture
    neckModel: 8, // 0-9
    neckBaseURL:
      "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png", // URL of Neck wear Texture
  },
];

export const attributesSelection = [
  {
    name: "weight",
    min: 20,
    max: 60,
  },
  {
    name: "ShoeType",
    value: ["Lightweight", "Trail", "Stability", "Motion Control", "Cushioned"],
  },
  {
    name: "Nurturing",
    min: 0,
    max: 20,
  },
  {
    name: "Coat",
    value: ["Longer", "Short", "Medium", "Double", "Curly", "Silky"],
  },
  {
    name: "Aerodynamics",
    min: 0,
    max: 10,
  },
  {
    name: "Tail",
    value: ["Bobbedm", "Curly", "Sickle", "Otter", "Whip", "Saber"],
  },
  {
    name: "Age",
    min: 0,
    max: 14,
  },

  {
    name: "BMI",
    value: ["Thin", "Ideal", "Overweight", "Obese"],
  },
];

export const cardRating = {
  Lightweight: 1,
  Trail: 2,
  Stability: 3,
  Motion_Control: 4,
  Cushioned: 5,
  Longer: 1,
  Short: 2,
  Medium: 3,
  Double: 4,
  Curly: 5,
  Silky: 6,
  Bobbedm: 1,
  Sickle: 3,
  Otter: 4,
  Whip: 5,
  Saber: 6,
  Thin: 1,
  Ideal: 2,
  Overweight: 3,
  Obese: 4,
};
