import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  usersTab: {
    position: "relative",
    background: "#EFEFEF",
    padding: "20px",
  },
}));
const userData = [
  {
    img: "/images/redbones.png",
    name: "red",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: " Village Mystic",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: "red",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: "red",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: "Red Village Mystic Bones",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: "red",
    subtext: "bones",
  },
  {
    img: "/images/redbones.png",
    name: "Red Village Mystic Bones",
    subtext: "bones",
  },
];
export default function Items({ searchData, handleClose }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const sendOnMarketPlace = () => {
    navigate("/marketplace");
    handleClose();
  };
  return (
    <Box className={classes.usersTab} style={{ maxHeight: "350px" }}>
      <Box className="">
        {searchData &&
          searchData.map((data) => (
            <Box
              style={{ cursor: "pointer" }}
              className="displayStart"
              mb={2}
              onClick={() => sendOnMarketPlace()}
            >
              <img src={data?.image} alt="Redbones" />
              <Box ml={2}>
                <Typography variant="body2" style={{ color: "#343A40" }}>
                  {data?.name}
                  &nbsp;
                  <span style={{ color: "rgba(52, 58, 64, 0.60)" }}>
                    {data?.description}
                  </span>{" "}
                </Typography>
              </Box>
            </Box>
          ))}
        {/* <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<BiSearch style={{color:"#fff"}}/>}
          >
            See all search results
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
}
