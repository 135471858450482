export default {
  h1: {
    fontFamily: "'Anybody', sans-serif",
    fontWeight: "700",
    fontSize: "100px",
    lineHeight: "114px",
    "@media (max-width:767px)": {
      fontSize: "50px",
      lineHeight: "75px",
    },
  },
  h2: {
    fontFamily: "'Anybody', sans-serif",
    fontWeight: "600",
    fontColor: "#313030",
    fontSize: "50px",
    lineHeight: "55px",
    "@media (max-width:767px)": {
      fontSize: "25px !important",
      lineHeight: "34px",
    },
  },
  h3: {
    fontFamily: "'Anybody', sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "35px",
    lineHeight: "40px",
  },
  h4: {
    fontFamily: "'Anybody', sans-serif",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "30px",
  },
  h5: {
    fontFamily: "'Anybody', sans-serif",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
  },
  h6: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "30px",
    "@media (max-width:767px)": {
      fontSize: "14px",
      lineHeight: "40px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "25px",
    "@media (max-width:767px)": {
      fontSize: "13px !important",
      lineHeight: "22px !important",
    },
  },
  body2: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "26px",
    "@media (max-width:767px)": {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
};
