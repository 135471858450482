import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Grow,
  Paper,
  Popper,
  MenuList,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../../component/Logo";
import CloseIcon from "@material-ui/icons/Close";
import MetaMaskpopup from "src/component/MetaMaskpoppup";
import { getNetwork } from "@wagmi/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { UserContext } from "src/context/User";
import { sortAddress } from "src/utils";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegramPlane,
  FaDiscord,
} from "react-icons/fa";
import {
  ConnectButton,
  RainbowKitProvider,
  lightTheme,
} from "@rainbow-me/rainbowkit";

import { useAccount } from "wagmi";
import { ACTIVE_NETWORK } from "src/constants";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { SiSinaweibo } from "react-icons/si";
import { BiSearch } from "react-icons/bi";
import SearchModal from "src/component/SearchModal";

const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about-us",
  },

  {
    label: "Add Qie",
    href: "/buy-qie",
  },
  // {
  //   label: "Breed",
  //   href: "/breed",
  // },
  {
    label: "Events",
    href: "/event",
  },
  {
    label: "Create Dog",
    href: "/mint",
  },
  {
    label: "Leader Board",
    href: "/top-rankers",
  },
  {
    label: "Pet Store",
    href: "/select-dog",
  },
  // {
  //   label: 'Learn',
  //   href: '/learn',
  // },
  {
    label: "Profile",
    href: "/profile",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#383838",
    // height: "31px",
    padding: "21px 10px",
    letterSpacing: "1px",
    fontFamily: "'Roboto', sans-serif",

    marginRight: "10px",
    borderBottom: "3px solid transparent",
    textDecoration: "none",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#029CDC",
      borderBottom: "3px solid #029CDC",
    },

    "&:hover": {
      color: "#029CDC",
      // borderBottom:"4px solid #029CDC",
    },
  },

  toolbar: {
    display: "flex",
    padding: "1px 0 0",
    justifyContent: "space-between",
    height: "100%",
    "& .MuiButton-containedSizeLarge": { padding: "8px 0" },
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "20px",
    width: "100px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#04141b",
    width: "284px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& a": {
      width: "100% !important",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "13px !important",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    width: "100%",
    //  borderBottom: "1px solid #3e3e3e",
    padding: "0px 0px 0px 17px",
    "@media (max-width: 500px)": {
      width: "100%",
      minHeight: "30px !important",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
    // paddingLeft: "15px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    "&.MuiButton-containedSizeLarge": { padding: "8px 0" },
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
}));

export default function Header() {
  const user = useContext(UserContext);
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer, // eslint-disable-line
    containerHeight,
    mainHeader,
    wallet, // eslint-disable-line
    submenu,
  } = useStyles();
  const { address } = useAccount();
  const { chain, chains } = getNetwork();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [isConnected1, setIsConnected] = useState([]);
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }
  // console.log(" ---- getConnectedNetwork ", getConnectedNetwork);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg">
        <Toolbar className={`scrollbar-hidden ${toolbar}`}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}
            &nbsp;
            <SearchModal />
            <ConnectButton />
            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "community")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "community")}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={`scrollbar-hidden ${mainHeader}`}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {getDrawerChoices(handleDrawerClose)}

            <Container>
              <RainbowKitProvider
                chains={chains}
                coolMode
                modalSize="compact"
                theme={lightTheme({
                  accentColor: "#029CDC",
                  accentColorForeground: "#fff",
                  borderRadius: "small",
                  fontStack: "system",
                  overlayBlur: "small",
                  fontWeight: "500px",
                })}
              >
                <ConnectButton />
              </RainbowKitProvider>

              <Box className="socildrawerBox">
                <Link to={user?.socialData[0]?.link} target="_blank">
                  <IconButton>
                    <FiFacebook />
                  </IconButton>
                </Link>

                <Link to={user?.socialData[3]?.link} target="_blank">
                  <IconButton>
                    <FaTelegramPlane />
                  </IconButton>
                </Link>
                <Link to={user?.socialData[2]?.link} target="_blank">
                  <IconButton>
                    <FaInstagram />
                  </IconButton>
                </Link>

                <Link to={user?.socialData[1]?.link} target="_blank">
                  <IconButton>
                    <FiTwitter />
                  </IconButton>
                </Link>
                <Link
                  to="https://discord.com/invite/9HCNTyqkwa"
                  target="_blank"
                >
                  <IconButton>
                    {" "}
                    <FaDiscord />
                  </IconButton>
                </Link>
                <Link to="https://weibo.com/pawsome" target="_blank">
                  <IconButton>
                    <SiSinaweibo />
                  </IconButton>
                </Link>
              </Box>
            </Container>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SearchModal />
          <Box ml={7}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    );
  };

  const getDrawerChoices = (handleDrawerClose) => {
    return isConnected1.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
              onClick: handleDrawerClose,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return isConnected1.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            activeClassName="active"
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };
  useEffect(() => {
    if (address) {
      setIsConnected(headersData);
    } else {
      const filterFun = headersData.filter((data) => {
        return data?.label !== "Profile" && data.label !== "Pet Store";
      });
      setIsConnected(filterFun);
    }
  }, [address]);
  return (
    <>
      <AppBar
        position={window.location.pathname !== "/" ? "absolute" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
        className="scrollbar-hidden"
      >
        <Box
          maxWidth={window.location.pathname !== "/" ? "lg" : "lg"}
          className={`scrollbar-hidden ${containerHeight}`}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>
      <Dialog
        open={user?.openmetamask}
        fullWidth
        maxWidth="xs"
        onClose={() => user.setOpenMetaMask(false)}
      >
        <Box
          style={{
            background: "linear-gradient(90deg, #39A9F4 0%, #62D3F0 100%)",
          }}
          align="right"
        >
          <IconButton onClick={() => user.setOpenMetaMask(false)}>
            <CloseIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
        <DialogContent>
          <MetaMaskpopup user={user} account={address} />
        </DialogContent>
      </Dialog>
    </>
  );
}
