import React, { createContext, useEffect, useState } from "react";
import { generativeNFTAddress } from "src/constants";
import { dataPostHandler, getDataHandlerAPI } from "src/apiConfig/service";
import { getWeb3ContractObject } from "src/utils";
import generativeNFTABI from "src/abi/generativeNFTABI.json";
import { useAccount, useBalance } from "wagmi";
import { useEthersSigner } from "src/utils/ethersSigner";
import { toast } from "react-toastify";
// import { useConnect } from "wagmi";
// // import { injected } from "@wagmi/connectors/injected";
// import { injected } from "wagmi/connectors";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};
function checkLogin() {
  const userType = window.sessionStorage.getItem("userType");
  return userType === "ADMIN" ? true : false;
}

export default function AuthProvider(props) {
  // const { activate, account, chainId, deactivate, library } = useWeb3React();
  const { address } = useAccount();
  // const { connect } = useConnect();
  const signer = useEthersSigner();
  const [openmetamask, setOpenMetaMask] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [isLoginAdmin, setIsLoginAdmin] = useState(checkLogin());
  const [contractDetails, setContractDetails] = useState({
    owner: "",
  });
  var continueSound = false;
  const balance = useBalance({
    address: address,
  });

  const connectToWallet = (data) => {
    // connect({ connector: injected() });
  };

  const disconnectWalletHandler = async () => {
    try {
      // deactivate();
      setOpenMetaMask(false);
      toast.success("You have been disconnected successfully!");
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("userType");
      window.sessionStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  const connectWalletAPIHandler = async () => {
    try {
      const dataToSend = {
        walletAddress: address,
      };
      const response = await dataPostHandler("connectWallet", dataToSend);
      if (response) {
        const token = response.data.data.token;
        const userType = response.data.data.userType;
        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userType", userType);
        getProfileDataHandler(token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDataHandler = async (token) => {
    setIsLoading(true);
    try {
      const response = await getDataHandlerAPI("getProfile", token);
      if (response) {
        setUserData(response);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getUserbalce = async () => {
    try {
      setYourWalletBalance(balance?.data?.formatted);
      // var web3 = new Web3(signer);
      // const balance = await web3.eth.getBalance(address);
      // const balanceImETH = await web3.utils.fromWei(balance);
      // console.log(" ----balanceImETH ", balanceImETH);
      // setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
      // const ethersProvider = providers();
      // const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      // const address = await signer.getAddress();

      // console.log("address ----balanceImETH ", address);
      // Get user's balance in ether
      // const balance = ethers.utils.formatEther(
      //   await ethersProvider.getBalance(address) // Balance is in wei
      // );

      // console.log("balance ----balanceImETH ", balance);
      // return balance
    } catch (error) {
      console.log("error ----balanceImETH ", error);
    }
  };

  const staticContentList = async () => {
    setIsLoading(true);
    try {
      const res = await getDataHandlerAPI("staticContentList");
      if (res) {
        setCollection(res);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getContractDetailsHandler = async () => {
    try {
      const contract = getWeb3ContractObject(
        generativeNFTABI,
        generativeNFTAddress
      );
      const ownerFun = await contract.methods.owner().call();
      setContractDetails({
        owner: ownerFun.toString(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  //SocialLink
  const SocialLinkListHandler = async () => {
    try {
      const response = await getDataHandlerAPI(
        "socialLinks",
        window.sessionStorage.getItem("token")
      );
      if (response) {
        setSocialData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    staticContentList();
    SocialLinkListHandler();
    getContractDetailsHandler();
  }, [window.sessionStorage.getItem("token")]); // eslint-disable-line

  //context-data-layer-object
  let data = {
    isAdmin: isLoginAdmin,
    userData,
    openmetamask,
    collection,
    socialData,
    yourWalletBalance,
    isLoading,
    contractDetails,
    continueSound,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    setOpenMetaMask: (data) => setOpenMetaMask(data),
    getProfileDataHandler: (data) => getProfileDataHandler(data),
    disconnectWallet: () => disconnectWalletHandler(),
    checkLogin: () => checkLogin(),
    setIsLoginAdmin: (data) => setIsLoginAdmin(data),
  };

  // useEffect(() => {
  //   if (account && chainId) {
  //     if (chainId !== ACTIVE_NETWORK) {
  //       if (window.ethereum) {
  //         swichNetworkHandler();
  //       }
  //     }
  //   }
  // }, [chainId, account]); // eslint-disable-line

  useEffect(() => {
    if (address) {
      getUserbalce();
      connectWalletAPIHandler();
    }
  }, [address]); // eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
    // if (window.sessionStorage.getItem("token")) {
    //   getProfileDataHandler(window.sessionStorage.getItem("token"));
    // }
  }, [window.sessionStorage.getItem("token")]); //eslint-disable-line
  useEffect(() => {
    data.updateUser(address);
  }, [address]); //eslint-disable-line

  // useEffect(() => {
  //   if (sessionStorage.getItem("walletName")) {
  //     const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
  //       return (
  //         data?.data?.name?.toUpperCase() ==
  //         sessionStorage.getItem("walletName")
  //       );
  //     });
  //     if (selectectWalletDetails.length > 0) {
  //       connectToWallet(selectectWalletDetails[0].data);
  //     }
  //   }
  // }, [sessionStorage.getItem("walletName")]);
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
