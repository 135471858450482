import axios from "axios";
import Apiconfigs from "../config";
import { toast } from "react-toastify";
import { getContract } from "src/utils";
import moment from "moment";
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.code === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
    return false;
  }
};

export const getDataHandlerAPI = async (endPoint, token) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },
    });
    if (res.data.code === 200) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getDataTypeHandlerAPI = async (endPoint, token, type) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },
      params: type ? type : null,
    });
    if (res.data.code === 200) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.code === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    // console.log("res-----", res);
    if (res.data.code === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    // console.log("=-=-=>>>>", error.response);
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    if (error.response.data.code === 400) {
      toast.error(error?.response?.data?.message);
    }
    return error;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.code === 200) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: {
        _id: _id,
      },
    });
    if (res.data.code === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
    return false;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const addImageHandler = (img) => {
  const token = sessionStorage.getItem("token");

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", img);
    axios({
      method: "POST",
      url: Apiconfigs.ipfsUpload,
      data: formData,
      headers: {
        token,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        // console.log("res-----", res);
        if (res.data.statusCode === 200) {
          resolve(res.data.result.imageUrl);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        reject(false);
      });
  });
};
export const createNFTBlockchainHanlder = async (
  contractAddress,
  abi,
  signer,
  hash,
  name
) => {
  try {
    const contract = getContract(contractAddress, abi, signer);
    // console.log("contract----", contract);
    const createRes = await contract.create(hash, name);

    await createRes.wait();
    return true;
  } catch (error) {
    console.log("ERROR", error);
    return false;
  }
};
export const getTokenId = async (contractAddress, abi, signer) => {
  try {
    const tokenIDContract = await getContract(contractAddress, abi, signer);

    const tokenID = await tokenIDContract.totalSupply();

    let token = parseInt(tokenID.toString()) - 1;

    return token;
  } catch (error) {
    console.log("errr", error);
    // return false;
  }
};

export const listingAPIHandler = async (endPoint, page, type, filterData) => {
  try {
    const dataToSend =
      type !== "" && page !== 0 && filterData !== undefined
        ? {
            limit: 10,
            page: page,
            type: type,
            search: filterData.searchKey !== "" ? filterData.searchKey : null,

            toDate:
              filterData.endDate !== ""
                ? moment(
                    filterData.endDate,
                    "dd.MM.yyyy HH:mm:ss"
                  ).toISOString()
                : null,
            fromDate:
              filterData.startDate !== ""
                ? moment(
                    filterData.startDate,
                    "dd.MM.yyyy HH:mm:ss"
                  ).toISOString()
                : null,
          }
        : type !== "" && page === 0
        ? {
            type: type,
          }
        : type === undefined && page === undefined && filterData === undefined
        ? {
            limit: 10,
            page: page,
            type: type,
          }
        : {
            limit: 10,
            page: page,
            search: filterData.searchKey !== "" ? filterData.searchKey : null,
            eventType:
              filterData.eventType !== "" ? filterData.eventType : null,
            toDate:
              filterData.endDate !== ""
                ? moment(
                    filterData.endDate,
                    "dd.MM.yyyy HH:mm:ss"
                  ).toISOString()
                : null,
            fromDate:
              filterData.startDate !== ""
                ? moment(
                    filterData.startDate,
                    "dd.MM.yyyy HH:mm:ss"
                  ).toISOString()
                : null,
          };
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: dataToSend,
    });
    if (res.data.code === 200) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const listingWithToken = async (endPoint, page) => {
  try {
    const dataToSend = {
      limit: 10,
      page: page,
    };

    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res.data.dataResults;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
