import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { sortAddress } from "src/utils";
import { getNetwork } from "@wagmi/core";
import { useDisconnect } from "wagmi";
import { ACTIVE_NETWORK } from "src/constants";
import { switchNetwork } from "@wagmi/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 0px 50px 0px",
    "& .mainBox": {
      "& h3": {
        fontSize: "25px",
        color: "#383838",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
          fontSize: "18px",
          lineHeight: "30px",
        },
      },
      "& h4": {
        fontSize: "16px",
        fontWeight: "400",
        paddingTop: "10px",
      },
    },
  },

  learnMoreButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& Button": {
      color: "#383838",
      display: "flex",
      alignItems: "center",
    },
    "& a": {
      fontSize: "16px",
      color: "#383838",
      fontWeight: "500",

      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
      },
    },
  },
  metamaskbox: {
    display: "flex",
    alignItems: "center",
    padding: "8px 30px 8px 30px",
    maxWidth: "350px",
    border: "1px solid #383838",

    "& h3": {
      fontSize: "22px",
      fontWeight: "500",
      color: "#383838",
      paddingLeft: "30px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "3px",
      },
    },
  },
}));

function MetaMaskpopup({ user, account }) {
  const classes = useStyles();
  const { disconnect } = useDisconnect();
  const { chain } = getNetwork();

  const network = async () =>
    await switchNetwork({
      chainId: ACTIVE_NETWORK,
    });

  return (
    <Box className={classes.root}>
      <Box align="center" mt={2} mb={2} className="mainBox">
        {!account && (
          <Typography variant="h3">You need an account to play</Typography>
        )}
        {account && chain?.id != ACTIVE_NETWORK && (
          <Typography variant="h3">
            You need to switch QIE Mainnet to play
          </Typography>
        )}
        {/* {!account && (
          <Typography variant="h4">Connect or setup an account with</Typography>
        )} */}
        {account && chain?.id == ACTIVE_NETWORK && (
          <Typography variant="h3">
            You are on the QIE Mainnet network. Play now.
          </Typography>
        )}

        <Typography variant="h4">Connect or setup an account with</Typography>
      </Box>

      <Box className="metamaskButton" align="center" mt={4}>
        {account && chain?.id != ACTIVE_NETWORK && (
          <Box pb={2}>
            <Button onClick={network} className={classes.metamaskbox}>
              Switch to QIE Mainnet
            </Button>
          </Box>
        )}
        {account && chain?.id == ACTIVE_NETWORK && (
          <Button
            onClick={() => {
              if (!account) {
                user.connectWallet();
              } else {
                user.setOpenMetaMask(false);
                user.disconnectWallet();
                disconnect();
              }
            }}
            className={classes.metamaskbox}
          >
            {`Disconnect ${sortAddress(account)}`}
          </Button>
        )}
      </Box>

      {/* <Box className={classes.learnMoreButtons} align="center" mt={3}>
        <Button
          target="_blank"
          // href="https://www.youtube.com/playlist?list=PL37ZVnwpeshFqN5dcZ704lxI3F5iHDYkl"
        >
          Learn More <ChevronRightIcon style={{ color: "#383838" }} />
        </Button>
      </Box> */}
    </Box>
  );
}

export default MetaMaskpopup;
