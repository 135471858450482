import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  usersTab: {
    position: "relative",
    background: "#EFEFEF",
    padding: "20px",
  },
}));

export default function Dogs({ searchData }) {
  const classes = useStyles();
  return (
    <Box className={classes.usersTab} style={{ maxHeight: "350px" }}>
      <Box className="">
        {searchData &&
          searchData?.map((data) => (
            <Box className="displayStart" mb={2}>
              <img src={data?.uri} alt="Redbones" height="30px" width="30px" />
              <Box ml={2}>
                <Typography
                  variant="body2"
                  style={{ color: "#343A40", textTransform: "capitalize" }}
                >
                  {data?.petName}
                  &nbsp;
                  {/* <span style={{ color: "rgba(52, 58, 64, 0.60)" }}>
                    {data.subtext}
                  </span>{" "} */}
                </Typography>
              </Box>
            </Box>
          ))}
        {/* <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<BiSearch style={{ color: "#fff" }} />}
          >
            See all search results
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
}
