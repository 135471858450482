import _ from "lodash";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiAccordionDetails: {
      root: {
        paddingTop: "0px !important",
      },
    },
    MuiStepIcon: {
      text: {
        fill: "#fff !importnt",
        color: "#fff",
      },
      root: {
        color: "#C9C9C9",
        "& .MuiStepIcon-active": {
          color: "#47bcf2 !important",
        },
      },
    },
    MuiStepLabel: {
      label: {
        "&.MuiStepLabel-active": {
          color: " #47bcf2 ",
        },
        "& .MuiStepLabel-completed": {
          color: "#47bcf2 !important",
        },
      },
      root: {
        "& .MuiStepIcon-completed": {
          color: "#47bcf2 !important",
        },
      },
    },
    MuiAppBar: {
      positionAbsolute: {
        position: "fixed !important",
      },
      colorPrimary: {
        backgroundColor: "#fff !important",
      },
      positionRelative: {
        position: "fixed !important",
      },
    },
    // MuiAppBar:{
    //   colorPrimary:{
    //     backgroundColor:"#fff",
    //   },
    // },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiFormHelperText:{
      contained:{
        marginLeft:"0px",
        marginRight:"0px",
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#029CDC",
      },
    },

    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    // MuiOutlinedInput: {
    //   inputAdornedEnd: {
    //     padding: "23px 15px",
    //   },
    //   inputAdornedStart: {
    //     paddingLeft: "15px",
    //   },
    //   input: {
    //     padding: "22.5px 14px",

    //     borderRadius: "5px",
    //   },
    //   notchedOutline: {
    //     borderColor: "#029CDC",
    //   },
    //   colorSecondary: {
    //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //       color: "#222",
    //       borderColor: "#fff",
    //     },
    //     "&.Mui-focused": {
    //       color: "#222",
    //     },
    //   },
    // },
    MuiInputBase: {
      root: {
        color: "#000",
        height: "55px",

        borderRadius: "10px !important",
        // height: "50px !important",
      },
    },

    MuiOutlinedInput: {
      inputMultiline: {
        padding: "1px !important",
      },
      multiline: {
        height: "123px !important",
        display: "flex",
        alignItems: "flex-start",
      },
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(53, 165, 245, 1)",
        },
      },

      inputAdornedStart: {
        paddingLeft: "8px",
      },
   
      inputAdornedEnd: {
        paddingRight: "5px",
      },
      input: {
        borderRadius: "10px",
        color: "#383838",
        padding: "22px 14px",
        fontSize: "14px",
        fontWeight: "400",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#383838",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&::before": {
            display: "none",
          },
          "&::after": {
            display: "none",
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "20px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
      }, // eslint-disable-next-line
      rounded: {
        borderRadius: "0px",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation3: {
        position: "relative",
        zIndex: "999",
        overflow: "hidden",
        padding: "20px",
        // boxShadow: "-8px -8px 13px 0px #ffffff, 6px 13px 20px #d2d1db",
        borderRadius: "20px !important",
        backgroundColor: "#ffffff",
        "@media(max-width:767px)": {
          padding: "20px !important",
        },
      },
    },
    MuiStepper: {
      alternativeLabel: {
        background: "transparent",
      },
    },

    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: "550 !important",
        maxWidth: "100%",
      },
      paper: {
        margin: "32px",
        position: "relative",
        overflowY: "unset",
        // borderRadius: "10px !important",
        border: "2px solid #2196F3",
      },
      paperWidthSm: {
        // width:'500px !important',
        maxWidth: "500px !important",
      },

      paperWidthXs: {
        maxWidth: "500px ",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 16,
        color: "#000",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiSelect: {
      icon: {
        color: "#000",
        fontSize: "20px",
      },
    },
    MuiTableHead: {
      root: {
        background:
          "linear-gradient(90deg, rgb(57, 169, 244) 0%, rgb(98, 211, 240) 100%) rgb(2, 156, 220)",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiTableBody: {
      root: {
        background:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: "1px solid #636262",
        "&:hover": {
          backgroundColor: "#ffffff14",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiTableCell: {
      head: {
        padding: "14px 16px",
        fontWeight: "300",
        color: "#fff",
        whiteSpace: "pre",
      },
      body: {
        color: "#0D0D0D",
        whiteSpace: "pre",
      },
    },
    MuiDialogActions: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiButton: {
      containedSecondary: {
        borderRadius: "0px",
        color: "#fff",
        fontSize: "14px  !important",
        background: "linear-gradient(90deg, #39A9F4 0%, #62D3F0 100%)",
        fontWeight: "500",
        padding: "10px 25px",
        whiteSpace: "pre",
        "&:hover": {
          backgroundColor: "#f30065",
          color: "#fff",
        },
        "@media (max-width:767px)": {
          fontSize: "12px !important",
        },
      },

      containedPrimary: {
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        color: "#000",
        fontSize: "14px  !important",
        fontWeight: "500",
        padding: "10px 25px !important",
        "&:hover": {
          background: "linear-gradient(90deg, #39A9F4 0%, #62D3F0 100%)",
          color: "#fff",
        },
        "@media (max-width:767px)": {
          fontSize: "12px !important",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#f30065",
        fontWeight: 600,
        padding: "5px 19px",
      },
      outlinedPrimary: {
        background: " #FFFFFF",
        border: " 1px solid #029CDC",
        borderRadius: "11px",
        color: "#000",
        fontWeight: 600,
        padding: "5px 19px",
        width: "150px",
        height: "50px",
        "&:hover": {},
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "9px",
        overflow: "visible",
        fontSize: "14px",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000 ",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
      },
    },
  },
};

const themesOptions = {
  palette: {
    primary: {
      main: "#47bcf2",
      color: "#fff",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
