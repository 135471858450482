import React from "react";
import { makeStyles } from "@material-ui/core";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100vw",
    overflow: "hidden",
  },
  MainLayout: {
    minHeight: "calc(100vh - 200px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          window.location.pathname == "/" // eslint-disable-line
            ? { display: "block" }
            : { display: "block" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>

      <Footer />
    </div>
  );
};

export default MainLayout;
