import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import SerachTab from "./SerachTab";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { listingWithToken } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import NoDataFound from "./NoDataFound";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { ConnectButton } from "@rainbow-me/rainbowkit";
const useStyles = makeStyles((theme) => ({
  mainserchModal: {
    "& .MuiDialog-paperWidthXs": {
      top: "47px",
      right: "0",
      position: "absolute",
      maxWidth: "368px",
      borderRadius: "10px",
    },
  },
  shareText: {
    position: "relative",
    "& .closeButton": {
      top: "16px",
      right: "11px",
      padding: "0px",
      position: "absolute",
    },
    "& h6": {
      color: "#fff",
    },
  },
}));
function SearchModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.mainserchModal} style={{ position: "relative" }}>
      {/* <Box style={{ position: "absolute", left: "0px", bottom: "0px" }}> */}
      <IconButton
        onClick={() => setNotificationOpen(true)}
        style={{
          background: "transparent",
          borderLeft: "1px solid #383838",
          borderRadius: "0px",
          padding: "12px",
        }}
      >
        <NotificationsIcon />
      </IconButton>
      {/* </Box> */}
      <IconButton onClick={handleClickOpen} style={{ marginRight: "11px" }}>
        <BiSearch style={{ fontSize: "23px", color: "#383838" }} />
      </IconButton>
      {/* <ConnectButton /> */}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Box align="center">
              <SerachTab handleClose={handleClose} />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {notificationOpen && (
        <NotificationList
          notificationOpen={notificationOpen}
          setNotificationOpen={setNotificationOpen}
        />
      )}
    </Box>
  );
}
export default SearchModal;

const NotificationList = ({
  handleClose,
  notificationOpen,
  setNotificationOpen,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [notificationData, setNotificationData] = useState([]);
  const listNotificationHandler = async () => {
    try {
      const res = await listingWithToken("listNotification", page, "", "");
      if (res.docs) {
        setNotificationData(res.docs);
        setIsLoading(false);
        setPageSize(res.pages);
      } else {
        setNotificationData([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setNotificationData([]);
    }
  };
  useEffect(() => {
    listNotificationHandler();
  }, [page]);
  const navigate = useNavigate();

  const navigateURL = (id) => {
    setNotificationOpen(false);
    navigate(`/event?${id}?${`TounamenTab`}`);
  };
  const pageCheck = page === 1 ? 10 : 0;
  return (
    <>
      <Dialog
        open={notificationOpen}
        onClose={() => setNotificationOpen(false)}
        fullWidth
        maxWidth="lg"
        style={{
          position: "absolute",
          // top: "47px",
          right: "0",
        }}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Box>
              {/* <NotificationList
                handleClose={() => setNotificationOpen(false)}
              /> */}
              <Box>
                <Box>
                  <Box
                    style={{
                      position: "relative",
                      background: "#EFEFEF",
                      padding: "20px",
                    }}
                  >
                    <Box className="displaySpacebetween" pb={3}>
                      <Typography
                        variant="h5"
                        style={{
                          paddingBottom: "3px",
                          color: "#000",
                          textAlign: "left",
                        }}
                      >
                        Notification List
                      </Typography>
                      {/* <Typography
              variant="h5"
              style={{
                paddingBottom: "3px",
                color: "#000",
                textAlign: "left",
                borderBottom: "1px solid #0f0fdc",
              }}
            >
              See All
            </Typography> */}
                    </Box>
                    <Box style={{ maxHeight: "350px" }}>
                      {isLoading ? (
                        <ButtonCircularProgress />
                      ) : (
                        <>
                          {notificationData &&
                            notificationData.map((data) => (
                              <Box
                                className="displayStart"
                                mb={2}
                                onClick={() =>
                                  navigateURL(
                                    data?.tournamentId ? data?.tournamentId : ""
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={data?.image}
                                  alt="Redbones"
                                  width="70px"
                                />
                                <Box ml={2}>
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#343A40" }}
                                  >
                                    {`${data?.title}/${data?.name}`}
                                    &nbsp;
                                  </Typography>
                                  <Typography
                                    style={{ color: "rgba(52, 58, 64, 0.60)" }}
                                  >
                                    {data?.body}
                                  </Typography>{" "}
                                </Box>
                              </Box>
                            ))}
                          {notificationData &&
                            notificationData.length === 0 && <NoDataFound />}
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        {notificationData && notificationData.length >= pageCheck && (
          <DialogActions>
            <Pagination
              count={pageSize}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
