import { sortAddress } from "src/utils";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  usersTab: {
    position: "relative",
    background: "#EFEFEF",
    padding: "20px",
  },
}));

export default function Users({ searchData, handleClose }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const navigateURL = (id) => {
    handleClose();
    navigate(`/profile?${id}?${`Otherprofile`}`);
  };
  return (
    <Box className={classes.usersTab} style={{ maxHeight: "350px" }}>
      <Box className="">
        {searchData &&
          searchData?.userData?.length > 0 &&
          searchData?.userData?.map((data) => (
            <Box
              className="displayStart"
              mb={2}
              onClick={() => navigateURL(data?._id)}
            >
              <img
                src={data?.profilePic}
                alt="Redbones"
                height="30px"
                width="30px"
              />
              <Box ml={2}>
                <Typography variant="body2" style={{ color: "#343A40" }}>
                  {data?.walletAddress && sortAddress(data?.walletAddress)}
                  &nbsp;
                  {/* <span style={{ color: "rgba(52, 58, 64, 0.60)" }}>
                  {data.subtext}
                </span>{" "} */}
                </Typography>
              </Box>
            </Box>
          ))}
        {/* <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<BiSearch style={{ color: "#fff" }} />}
          >
            See all search results
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
}
