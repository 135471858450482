import LoginLayout from "src/layouts/LoginLayout/inedx";
import React, { lazy } from "react";
const HomeLayout = lazy(() => import("../layouts/HomeLayout"));
const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));
const GameLayout = lazy(() => import("../layouts/GameLayout"));

const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Aboutbanner")),
  },
  {
    exact: true,
    path: "/event",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Event/Event")),
  },
  {
    exact: true,
    path: "/topreward",
    layout: HomeLayout,
    element: lazy(() => import("src/component/TopReward")),
  },
  {
    exact: true,
    path: "/stakeholder-rewards",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/StakeHolder")),
  },
  // {
  //   exact: true,
  //   path: "/learn",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/ LearnSetWallet/LearnSetWallet")
  //   ),
  // },

  {
    exact: true,
    path: "/404",
    element: lazy(() => import("src/views/errors/NotFound")),
  },
  // {
  //   exact: true,
  //   path: "/event",
  //   layout: HomeLayout,
  //   element: lazy(() => import("src/views/pages/Home/Event/Event")),
  // },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Mint/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,

    element: lazy(() => import("src/views/pages/Home/ExploreMarketplace")),
  },
  {
    exact: true,
    path: "/race-time",
    element: lazy(() => import("src/component/RaceTime")),
  },
  // Profile section
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,

    element: lazy(() => import("src/views/pages/Home/Profilepage/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,

    element: lazy(() => import("src/views/pages/Home/Profilepage/EditProfile")),
  },
  {
    exact: true,
    path: "/breed",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Breed")),
  },
  {
    exact: true,
    path: "/buy-qie",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/AddQui")),
  },
  {
    exact: true,
    path: "/top-rankers",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Top-Rankers")),
  },
  {
    exact: true,
    path: "/newbreed",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/NewBreed")),
  },
  {
    exact: true,
    path: "/raceresult",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/RaceResult")),
  },
  {
    exact: true,
    path: "/raceresultdata",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/RaceNow/RaceResultData")),
  },
  {
    exact: true,
    path: "/raceresultdata-tournament",
    layout: HomeLayout,
    element: lazy(() =>
      import("src/views/pages/Home/RaceNow/TournamentRaceResult")
    ),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Footersection/Privacy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Footersection/TermsCondition")
    ),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Footersection/Aboutus")),
  },
  {
    exact: true,
    path: "/racenow",
    layout: GameLayout,
    element: lazy(() => import("src/views/pages/Home/RaceNow/Racenow")),
  },
  {
    exact: true,
    path: "/racenowroom",
    layout: GameLayout,
    element: lazy(() => import("src/views/pages/Home/RaceNow/RaceInRoom")),
  },
  {
    exact: true,
    path: "/select-dog",
    layout: HomeLayout,
    element: lazy(() => import("src/component/SelectDog")),
  },
  {
    exact: true,
    path: "/listevent",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Event/ListEvent")),
  },
  {
    exact: true,
    path: "/eventmanagement",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/Eventmanagement")
    ),
  },
  {
    exact: true,
    path: "/addevent",
    layout: HomeLayout,
    element: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },
  {
    exact: true,
    path: "/admin-listevent",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Home/Event/ListEvent")),
  },

  {
    exact: true,
    path: "/admin-addevent",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },
  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Home/Dashboard/Dashboard")),
  },
  {
    guard: true,
    exact: true,
    path: "/event-management",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },
  {
    guard: true,
    exact: true,
    path: "/nft-transaction",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/NftTransaction")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/product-transaction",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/ProductTransaction")
    ),
  },

  // Login file
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    element: lazy(() => import("src/views/auth/LogIn/index")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    // guard: true,
    element: lazy(() => import("src/views/auth/forgot-password/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    // guard: true,
    element: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/OtpVerification/VerifyOtp")),
  },

  // Dashboard file

  {
    guard: true,
    exact: true,
    path: "/eventmanagement",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/Eventmanagement")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/practice-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import(
        "src/views/pages/Home/Dashboard/PracticeManagement/PracticeManagement"
      )
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/Add-Practice",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/PracticeManagement/AddPractice")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/staticManagement/StaticManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/FaqManagement/FaqManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/edit-faq",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/FaqManagement/Edit")
    ),
  },
  {
    exact: true,
    path: "/social-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/socialManagement/socialMagements")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/edit",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/staticManagement/Edit")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/store-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/StoreManagement")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/usermanagemant",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/Usermanagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    element: lazy(() => import("src/views/pages/Home/Dashboard/ViewUser")),
  },
  {
    // guard: true,
    exact: true,
    path: "/subscription-management",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/SubscriptionList")
    ),
  },
  {
    exact: true,
    path: "/tournament-race",
    layout: GameLayout,
    element: lazy(() => import("src/views/pages/Home/RaceNow/TournamentRace")),
  },

  {
    guard: true,
    exact: true,
    path: "/add-store-item",
    layout: DashboardLayout,
    element: lazy(() =>
      import("src/views/pages/Home/Dashboard/storeManagement/AddStoreItem")
    ),
  },

  {
    exact: true,
    path: "/404",
    element: lazy(() => import("../views/errors/NotFound")),
  },
];

export default routes;
